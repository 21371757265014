import { NewsContextType } from '$context/NewsContext'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import wrapper from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import WelcomePromoBlock from '$components/blocks/WelcomePromoBlock/WelcomePromoBlock'
import MainContainer from '$components/container/MainContainer/MainContainer'
import PageWrapper from '$components/PageWrapper/PageWrapper'

import { axiosData } from '$utils/axiosData'

import MetaTags from '$/other/meta-tags/MetaTags'

export interface MainPageProps {
  data?: NewsContextType | undefined
}

export default function IndexPage() {
  const description = useAppSelector((state) => state.config.promo.description)
  const dictionary = useLanguageDictionary()

  return (
    <>
      <MetaTags title={dictionary.header.main} description={description} />
      <MainContainer>
        <PageWrapper>
          <WelcomePromoBlock />
        </PageWrapper>
      </MainContainer>
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(
  ({ getState }) =>
    async (): Promise<{ props: MainPageProps }> => {
      const { viewTemplate } = getState().config.news_blog ?? {}
      const { status } = getState().config.promo ?? {}

      const notNews = status === 'placeholder' || status === 'postpromo'

      if (viewTemplate !== 'block' || notNews) {
        return {
          props: {},
        }
      }

      try {
        const [news = [], categories = [], tags = []] = await Promise.all([
          axiosData(pbApi.getNews()),
          axiosData(pbApi.getCategories()),
          axiosData(pbApi.getTags()),
        ])
        return {
          props: {
            data: {
              news,
              categories,
              tags,
            },
          },
        }
      } catch {
        return {
          props: {
            data: {
              news: [],
              categories: [],
              tags: [],
            },
          },
        }
      }
    },
)
